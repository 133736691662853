import React, {useState} from 'react'
import BasicTableCell from './BasicTableCell';
//import "../../../sass/components/reuseable_components/_table_area.scss";

export default function BasicTable({data, headers}) {
    return (
       <>
        {
            data.map((column, index) => <BasicTableCell data={column.tableCell} index={index} label={headers?.tableColumns[index].tableCell} /> )
        }
       </>
    )
}
