import React, {useState} from 'react'
import BasicTableColumn from './BasicTableColumn';
//import "../../../sass/components/reuseable_components/_table_area.scss";

export default function BasicTable({data, headers}) {

    return (
        data.isHeaderRow === true ? 
            <thead>
                <tr>
                    <BasicTableColumn data={data.tableColumns}/>
                </tr>
            </thead> 
        : 
            <tr className={data.highlightRow === true ? 'highlight' : ""}>
                <BasicTableColumn data={data.tableColumns} headers={headers}/>
            </tr>
    )
}
