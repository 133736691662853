import React, {useState} from 'react'
import BasicTableRow from './BasicTableRow';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_table_area.scss";

export default function BasicTable(data, { isInView}) {

    const rows = data.tableRow;
    const removeBottomMargin = data.removeBottomMargin;
    const headers = rows.reduce(row => {
        if(row.isHeaderRow === true){
            return(row);
        }
    });
    
    
    if(removeBottomMargin === true){
    classList.push('no-margin');
    }

    let classList = [
    'table-area',
    'contains-custom-colour',
    'section',
    ]; 

    const [isInview, setIsInview] = useState(false);

    function inviewOnChange (isVisible) {
        setIsInview(isVisible)
    }
  
  
    // const checkColumn = (column) => {
       
       
    //        return(
    //            <td>test</td>
    //        )
       
    // }
    // const checkRow = (row) => {
    //    console.log(row)
    //    if(row.isHeaderRow === true){
    //        return(
    //            <thead>{checkColumn(row.tableColumns)}</thead>
    //        )
    //    }
    // }
    
    return (
        <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
            {({isVisible}) =>
            
                <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`} >
                    <div className="wrapper-900">
                    {/* // if have rows */}
                    
                    <div className="table-wrap">
                        <table className="table">
                            {
                        
                                rows.map(row => <BasicTableRow data={row} headers={headers} />)
                            }
                        </table>
                    </div>
                    </div>
                </section>
            
            }
        </VisibilitySensor>
    )
}
